import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.module.scss';

const Index = () => {
    const url = 'https://friends.casino:4000';
    // const url = 'http://localhost:4000'
    React.useEffect(() => {
        // Initialize iframe communication
        import('./iframeCommunication').then(({ default: IframeCommunication }) => {
            const iframeComm = new IframeCommunication('#myIframe', url);
            iframeComm.init();

            // Cleanup on component unmount
            return () => iframeComm.cleanup();
        });
    }, []);

    return (
        <React.StrictMode>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '100vh'}}>
                <div style={{flex: 1, padding: '20px', boxSizing: 'border-box'}}>
                    <App/>
                </div>
                <iframe
                    // ref={iframeRef}
                    id="myIframe"
                    src={`${url}${window.location.pathname}`}
                    width="100%"
                    height="100%"
                    allow="camera; microphone; clipboard-write; clipboard-read"
                />
            </div>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Index/>);

serviceWorkerRegistration.register();
